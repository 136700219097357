import React, { useState, useEffect } from "react";
import { useNavigate, Navigate, useParams, useLocation } from 'react-router-dom';
import { getAuth } from "firebase/auth";
import { useAuthContext } from './../../AuthContext';
import IfProxy from './../../IfProxy';
import IfProxyLimitedRequest from './../../IfProxyLimitedRequest';
import IfProxyCalender from './../../IfProxyCalender';


import Head from './../HeadFooter_ForMainH'; // 特殊ヘッダそち
import CardListView from './../../common/CardListView';
import AccountCard from './../../common/AccountCard';
import RequestCard from './../../common/RequestCard';
import StraitLineSignupButton from './../../common/StraitLineSignupButton';
import DisplayAd from './../../common/adsence/DisplayAd';
import SwipeMaster from './../../common/SwipeListView/SwipeMaster';


// ReactGA Analytics
import ReactGA from "react-ga4";

// カルーセル
import { Carousel } from 'react-responsive-carousel'
import "react-responsive-carousel/lib/styles/carousel.min.css";

import Grid from '@mui/material/Grid';


import TagInput from './../../common/TagInput';
import SnsUtil from './../../common/SnsUtil';

// スピナーアニメーション
import { Dna } from 'react-loader-spinner'

// Material UI
import { makeStyles } from '@mui/styles';

import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import { IconButton } from '@mui/material';

// アイコン
import EditCalendarTwoToneIcon from '@mui/icons-material/EditCalendarTwoTone';
import AnalyticsTwoToneIcon from '@mui/icons-material/AnalyticsTwoTone';
import QuestionAnswerTwoToneIcon from '@mui/icons-material/QuestionAnswerTwoTone';
import AvTimerTwoToneIcon from '@mui/icons-material/AvTimerTwoTone';
import SwipeViewCommonLogic from "../../common/SwipeListView/SwipeViewCommonLogic";

/**
 * ここは、「あなた」を参照しない
 * currentuser. を参照しない。そうすると、認証を問わず見られる（ヘッダは？）　TODO
 *  */
const MainList = () => {
  /**
   * @enum { (
   * CPRO: String, 
   * CREC: String, 
   * ONED: String, 
   * RT: String, 
   * SPEE: String, 
   * NOHI: String, 
   * RATI: String, 
   * DENS: String,
   * TAGS: String,) }
   * @param TYPE.CPRO 注目作品　CPRO
    @param TYPE.CREC 注目リクエスト CREC
    @param TYPE.ONED ワンドロマスター ONED
    @param TYPE.RT　リツイートマスター RT
    @param TYPE.SPEE　スピードマスター SPEE
    @param TYPE.NOTI　納品マスター NOHI
    @param TYPE.RATI　好評価　RATI
    @param TYPE.DENS　伝説リクエスト  DENS
    @param TYPE.TAGS　 タグ検索  TAGS
   * 
   */
  const TYPE = Object.freeze({
    CPRO: "CPRO",
    CREC: "CREC",
    ONED: "ONED",
    RT: "RT",
    SPEE: "SPEE",
    NOTI: "NOTI",
    RATI: "RATI",
    DENS: "DENS",
    TAGS: "TAGS"
  })

  const [onedroTag, setOnedroTag] = useState([]);
  const [tags, setTags] = useState([]);

  const [cyumokuProduct, setCyumokuProduct] = useState([]);
  const [cyumokuRequest, setCyumokuRequest] = useState([]);
  const [cyumokuRequestForDto, setCyumokuRequestForDto] = useState([]);

  const [newUser, setNewUser] = useState([]);
  const [newUserForDto, setNewUserForDto] = useState([]);
  const [chumokuUser, setChumokuUser] = useState([]);
  const [chumokuUserForDto, setChumokuUserForDto] = useState([]);



  const [limitedRequestData, setLimitedRequestData] = useState([]);


  const [onedroMaster, setOnedroMaster] = useState("");
  const [rtMaster, setRtMaster] = useState("");
  const [speedMaster, setSpeedMaster] = useState("");
  const [nohinMaster, setNohinMaster] = useState("");
  const [ratingMaster, setRatingMaster] = useState("");
  const [densetsu, setDensetsu] = useState("");

  const [userData, setUserData] = useState({});
  // ユーザがブックマークしたもので本日が実施日のものがあるか
  const [bookmark, setBookmark] = useState(false);


  const [loading, setLoading] = useState(true);
  const auth = getAuth();
  const navigate = useNavigate();
  const { user } = useAuthContext();
  const classes = useStyles();
  const ifProxy = new IfProxy();
  const ifProxyLimitedRequest = new IfProxyLimitedRequest();
  const ifProxyCalender = new IfProxyCalender();

  const location = useLocation();

  const params = useParams();
  const [faidin, setFadein] = useState(false);


  useEffect(() => {
    setLoading(true)
    // ReactGA Analytics ---
    ReactGA.initialize("G-2ZCXE08H2S");
    ReactGA.send({
      hitType: "pageview",
      // アクセスしたパス (pathname) とクエリ文字列 (search) を送付する (必要に応じて編集する)
      page: location.pathname + location.search,
    });
    // ------
    loadData();
  }, []);
  const loadData = () => {

    if (user) {
      if (!userData) {
        ifProxy.getUserData(user.uid).then(resultUserData => {
          setUserData(resultUserData)
          ifProxy.gaUsetSignupEvent(resultUserData)
        })
      }
      ifProxyCalender.getTodayFavoByUid(user.uid).then(result => {
        console.log(result)
        setBookmark(result)
      })

    }

    ifProxyLimitedRequest.getRemitedRequestList(10).then((result) => {
      //console.log("~~~~~~~~~~~~~~~~~")
      //console.log(result)
      setLimitedRequestData(result);
    })


    /** リスト用 */
    ifProxy.getCyumokuProduct(5).then(async (result) => {
      setCyumokuProduct(result)
      createTag(result);
      createOnedroTagString(result);

      // TODO: 暫定措置 コールドスタート問題 投稿Product不足を補うため、サンプルを保管する
      ifProxy.getPortforioProductList(100).then(async (result) => {
        // TODO ソートアルゴリズムを入れる
        // 取得を過去直近100ぐらいに広げる
        //console.log("前")
        //console.log(result)
        //暫定 ランダム
        result.sort(() => Math.random() - 0.5);
        //console.log("後")

        //console.log(result)
        result.slice(20)

        // スコアリング rankScore: 0
        // 最新の5件以内 +15点

        // シェア数上位5位以内 + 5点

        // いいね数上位5以内 +5点

        // pv上位5件以内 +5点

        // rankScoreで降順ソート
        // 上位20件だけにする
        // 次でconcatしてリストに追加する

        setCyumokuProduct(pre => {
          const addedPortforio = pre.concat(result)
          return addedPortforio
        })

        createTag(result)


        createOnedroTagString(result)

        setLoading(false)
        setFadein(true)

      })

    })
    ifProxy.getCyumokuRequest(5).then((result) => {
      setCyumokuRequest(result)
      const forDto = new SwipeViewCommonLogic();
      setCyumokuRequestForDto(forDto.createDtoFromRequestArray(result))

    })
    ifProxy.getNewUser(2).then((result) => {
      setNewUser(result)
      const forDto = new SwipeViewCommonLogic();
      setCyumokuRequestForDto(forDto.createDtoFromAccountArray(result))

    })
    ifProxy.getChumokuUser(20).then((result) => {
      setChumokuUser(result)
      const forDto = new SwipeViewCommonLogic();
      setCyumokuRequestForDto(forDto.createDtoFromAccountArray(result))

    })


    /** タイトル系 */
    /** TODO これ毎回読み込むべきか。開いたら表示、ぐらいにして節約できないか */
    /* 休止
    ifProxy.getOnedroMaster(1).then((result) => {
      setOnedroMaster(result[0])

    })
    ifProxy.getRtMaster(1).then((result) => { setRtMaster(result[0]) })
    ifProxy.getSpeedMaster(1).then((result) => { setSpeedMaster(result[0]) })
    ifProxy.getNohinMaster(1).then((result) => {
      setNohinMaster(result[0])
    })
    ifProxy.getRatingMaster(1).then((result) => { setRatingMaster(result[0]) })
    ifProxy.getDensetsu(1).then((result) => { setDensetsu(result[0]) })
    */

  }

  const createTag = (result) => {
    // 投入する形式
    /*
    result = [
      {
        id: 1,
        tagsString: ['オリジナル', 'オリジナル2', 'オリジナル3', 'オリジナル4',]
      },]
*/
    const tagsagList = result.map((item, index) => {
      // 項目名がtagsString固定なので加工が必要
      return { id: item.id, tagsString: item.tagsString }
    })


    setTags(pre => {
      const addedPortforio = pre.concat(tagsagList)
      return addedPortforio
    })
  }
  const createOnedroTagString = (result) => {
    const ondroTagList = result.map((item, index) => {
      // 項目名がtagsString固定なので加工が必要
      return { id: item.id, tagsString: item.onedroTagString }
    })
    setOnedroTag(pre => {
      const addedPortforio = pre.concat(ondroTagList)
      return addedPortforio
    }
    )
  }

  // フッターメニュー
  const footerMenu = () => {
    return (<>
      <Box className={classes.footerMenu}>

        {/* カレンダー */}
        <IconButton onClick={() => navigate('/OnedroCalender')} >
          <EditCalendarTwoToneIcon sx={{ height: 70, width: 70 }} />
          今週の企画
        </IconButton>
        {/* タイマー */}
        <IconButton onClick={() => navigate('/v2/SetupTimer')} >
          <AvTimerTwoToneIcon sx={{ height: 70, width: 70 }} />
          ワンドロタイマー
        </IconButton>

      </Box>



    </>)
  }

  // ログイン中かどうかを確認し、ログインユーザを保持していなかったらログイン画面へ
  // !!!! 未ログイン!!!!
  if (!user) {

    if (loading) {
      return (
        < Box className={classes.contentWrapper} >
          { /*****未ログイン *********/}

          <Box className={classes.mainBox}>
            <p>読み込み中...</p>
            <Dna
              visible={true}
              height="80"
              width="80"
              ariaLabel="dna-loading"
              wrapperStyle={{}}
              wrapperClass="dna-wrapper"
            />
          </Box>
        </Box >

      )
    } else {
      return (
        <>
          <Box className={classes.contentWrapper}>
            <DisplayAd adType="yokonaga" />

            { /*****未ログイン *********/}

            <Box className={classes.mainBox} >
              <Button
                variant="outlined"
                fullWidth
                onClick={() => navigate('/login')}
                color="info"
                sx={{ margin: 2 }}>
                ログイン</Button>
              {/* ヒーローイメージ */}
              <Carousel>
                <Box sx={{ opacity: faidin ? 1 : 0, transition: 'all 0.3s', }} onClick={() => navigate('/LP')} >
                  <img src={`${process.env.PUBLIC_URL}/heroImage1User.png`}
                  />
                </Box>
                <Box onClick={() => navigate('/RuleAndManner')} >
                  <img src={`${process.env.PUBLIC_URL}/heroImage2Creator.png`} />
                </Box>
              </Carousel>

              {/**　注目の作品　Productのリスト　直近で、閲覧数降順 */}
              <CardListView key="product" listData={cyumokuProduct} listTitle="注目の作品" listDiscription="サイト上で注目されている投稿されたワンドロ" type="product"></CardListView>

              <Box className={classes.centerBox} >
                <Button
                  variant="outlined"
                  fullWidth
                  onClick={() => navigate('/SearchListView/' + TYPE.CPRO)}
                  color="info"
                  sx={{ margin: 2 }}>
                  もっと見る</Button>
              </Box>
              {/**　無料限定リクエストのリクエストと作品　LimitedRequestListのリスト　直近で、閲覧数降順 */}
              <CardListView key="LimitedRequest" listData={limitedRequestData} listTitle="" listDiscription="無料限定リクエストのリクエストと作品" type="limitedRequest"></CardListView>


              <DisplayAd adType="yokonaga" />


              {/**　人気のキャラ　Requestのtagsタグ　直近でLimit */}
              <h1>人気のキャラ</h1>
              <Typography component="body" variant="secondary">
                ※ 投稿数がまだ少ないため現在タグ検索は無効状態としております
              </Typography>
              <Box className={classes.darkBackgroundBox} >
                {tags && <TagInput type="viewTag" tagViews={tags}></TagInput>}
              </Box>

              {/**　人気のキャラ　RequestのonedroTagタグ　直近でLimit */}
              <h1>人気のワンドロ企画</h1>
              <Typography component="body" variant="secondary">
                ※リクエストの多いワンドロ企画
                ※ 投稿数がまだ少ないため現在タグ検索は無効状態としております

              </Typography>
              <Box className={classes.darkBackgroundBox} >
                {onedroTag && <TagInput type="viewKikaku" tagViews={onedroTag}></TagInput>}
              </Box>







              { /*****未ログイン *********/}

              {/**　============== 機能メニューボタン系 ===============*/}
              <Box sx={{ margin: 10 }}>
                {/* なぜか直前の要素と重複し下のmarginで制御できないため、干渉Boxを作る */}
              </Box>


              <Grid key="menu_root" container justify="center" spacing={2} className={classes.gridMenuBackground}>
                {/** １段目 ===================================================================== */}
                {/** プロフィール情報グリッド */}



                <Grid key="menu_odai" item xs={3} className={classes.gridPadding}>
                  <Box className={classes.gridMenuGrid} onClick={() => {
                    if (!user) {
                      let result = window.confirm("ログインが必要です")
                      if (result) {

                      }
                    } else {
                      navigate('/Odai')
                    }

                  }
                  }
                  >
                    <QuestionAnswerTwoToneIcon sx={style.iconStyle}  ></QuestionAnswerTwoToneIcon>
                    <Typography sx={style.iconCaption}>
                      お題掲示板
                    </Typography>
                  </Box>
                </Grid>


                {/** 2段目 ===================================================================== */}

                { /*****未ログイン *********/}

                <Grid key="menu_nayami" item xs={3} className={classes.gridPadding}>
                  <Box className={classes.gridMenuGrid}>
                    <QuestionAnswerTwoToneIcon sx={style.iconStyle}  ></QuestionAnswerTwoToneIcon>
                    <Typography sx={style.iconCaption}>
                      お悩み相談(準備中)
                    </Typography>
                  </Box>
                </Grid>
                <Grid key="menu_trend" item xs={3} className={classes.gridPadding}>
                  <Box className={classes.gridMenuGrid}>
                    <AnalyticsTwoToneIcon sx={style.iconStyle}  ></AnalyticsTwoToneIcon>
                    <Typography sx={style.iconCaption}>
                      流行分析(準備中)
                    </Typography>
                  </Box>
                </Grid>
                <Grid key="menu_padding" item xs={3} className={classes.gridPadding}>

                </Grid>

              </Grid>
            </Box>
            <Box sx={{ margin: 10 }}>
              {/* なぜか直前の要素と重複し下のmarginで制御できないため、干渉Boxを作る */}
            </Box>



          </Box >
          {footerMenu()}

        </>

      )
    }
  } else if (loading) {
    return (

      <Box className={classes.contentWrapper}>
        <Box className={classes.mainBox}>
          <p>読み込み中...</p>
          <Dna
            visible={true}
            height="80"
            width="80"
            ariaLabel="dna-loading"
            wrapperStyle={{}}
            wrapperClass="dna-wrapper"
          />
        </Box>
      </Box>

    )
  } else {


    return (
      <>
        <Head></Head>
        <Box className={classes.contentWrapper}>
          <DisplayAd adType="yokonaga" />
          {bookmark && <Button color="error" onClick={() => navigate('/OnedroCalender')}>お気に入りした企画が本日開催されます</Button>}

          {(userData && userData.nextStep != "STEP_CLEARED") && <Box className={classes.centerBox}>
            <Typography variant="body1" sx={{ color: '#3299FF', fontFamily: 'ヒラギノ丸ゴ ProN' }}><b>次にやること</b></Typography>
            <Typography onClick={(() => navigate('/tu'))}
              variant="h4" sx={{ color: '#3299FF', fontFamily: 'ヒラギノ丸ゴ ProN' }}><b>{userData.nextStep}</b></Typography>
            <hr></hr>
          </Box>}
          {(userData && (userData.remitedCreator === undefined || !userData.remitedCreator)) && <Box className={classes.centerBox}>
            <Button variant="outlined" onClick={() => {
              ifProxy.updateUserRemitedCreator(userData)
              navigate('/user/' + user.uid)
              alert('プロフィール上で無料に限定したリクエスト受付機能を有効化されました。有償リクエストを受けられるようになるには別途クリエイター登録を行ってください。')
            }}>(口座登録不要)無料限定リクエスト受付を有効化</Button>
            <hr></hr>
          </Box>}


          <Box className={classes.mainBox} >
            {/* ヒーローイメージ */}
            <Carousel >
              <Box sx={{ opacity: faidin ? 1 : 0, transition: 'all 0.3s', }} onClick={() => navigate('/LP')} >
                <img src={`${process.env.PUBLIC_URL}/heroImage1User.png`}
                />
              </Box>
              <Box onClick={() => navigate('/RuleAndManner')} >
                <img src={`${process.env.PUBLIC_URL}/heroImage2Creator.png`} />
              </Box>
            </Carousel>

            {/* 広告枠 */}
            <Box key="ad_box">
            </Box>


            {/**　注目の作品　Productのリスト　直近で、閲覧数降順 */}
            <CardListView key="product" listData={cyumokuProduct} listTitle="注目の作品" listDiscription="サイト上で注目されている投稿されたワンドロ" type="product"></CardListView>

            <Box className={classes.centerBox} >
              <Button
                variant="outlined"
                fullWidth
                onClick={() => navigate('/SearchListView/' + TYPE.CPRO)}
                color="info"
                sx={{ margin: 2 }}>
                もっと見る</Button>
            </Box>
            {/**　無料限定リクエストのリクエストと作品　LimitedRequestListのリスト　直近で、閲覧数降順 */}
            <CardListView key="LimitedRequest" listData={limitedRequestData} listTitle="" listDiscription="無料限定リクエストのリクエストと作品" type="limitedRequest"></CardListView>

            <DisplayAd adType="yokonaga" />


            {/**　人気のキャラ　Requestのtagsタグ　直近でLimit */}
            <h1>人気のキャラ</h1>
            <Box className={classes.darkBackgroundBox} >
              {tags && <TagInput type="viewTag" tagViews={tags}></TagInput>}
            </Box>

            {/**　人気のキャラ　RequestのonedroTagタグ　直近でLimit */}
            <h1>人気のワンドロ企画</h1>
            <Typography component="body" variant="secondary">
              ※リクエストの多いワンドロ企画

            </Typography>
            <Box className={classes.darkBackgroundBox} >
              {onedroTag && <TagInput type="viewKikaku" tagViews={onedroTag}></TagInput>}
            </Box>


            {/**　注目のユーザー ランダムで9人 クリエイターのみ */}
            <h1>WIP 注目ユーザ</h1>
            <CardListView key="user" listData={chumokuUser} listTitle="注目のクリエイター" listDiscription="募集中のクリエイター" type="account"></CardListView>






            {/**　============== 機能メニューボタン系 ===============*/}
            <Box sx={{ margin: 10 }}>
              {/* なぜか直前の要素と重複し下のmarginで制御できないため、干渉Boxを作る */}
            </Box>


            <Grid container justify="center" spacing={2} className={classes.gridMenuBackground}>
              {/** １段目 ===================================================================== */}
              {/** プロフィール情報グリッド */}

              <Grid item xs={3} className={classes.gridPadding}>
                <Box className={classes.gridMenuGrid} onClick={() => navigate("/DiaryList")}>
                  <QuestionAnswerTwoToneIcon sx={style.iconStyle}  ></QuestionAnswerTwoToneIcon>
                  <Typography sx={style.iconCaption}>
                    ワンドロ日記
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={3} className={classes.gridPadding}>
                <Box className={classes.gridMenuGrid} onClick={() => navigate("/Odai")}>
                  <QuestionAnswerTwoToneIcon sx={style.iconStyle}  ></QuestionAnswerTwoToneIcon>
                  <Typography sx={style.iconCaption}>
                    お題掲示板
                  </Typography>
                </Box>
              </Grid>


              {/** 2段目 ===================================================================== */}


              <Grid item xs={3} className={classes.gridPadding}>
                <Box className={classes.gridMenuGrid}>
                  <QuestionAnswerTwoToneIcon sx={style.iconStyle}  ></QuestionAnswerTwoToneIcon>
                  <Typography sx={style.iconCaption}>
                    お悩み相談(準備中)
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={3} className={classes.gridPadding}>
                <Box className={classes.gridMenuGrid}>
                  <AnalyticsTwoToneIcon sx={style.iconStyle}  ></AnalyticsTwoToneIcon>
                  <Typography sx={style.iconCaption}>
                    流行分析(準備中)
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={3} className={classes.gridPadding}>

              </Grid>

            </Grid>
            <DisplayAd adType="squer"></DisplayAd>




            {/**　注目のリクエスト　Request　直近で、リアクション×2+pv 降順 */}

            <CardListView key="request" listData={cyumokuRequest} listTitle="注目のリクエスト" listDiscription="注目のリクエストを表示" type="request"></CardListView>
            <Box className={classes.centerBox} >
              <Button
                variant="outlined"
                fullWidth
                onClick={() => navigate('/SearchListView/' + TYPE.CREC)}
                color="info"
                sx={{ margin: 2 }}>
                もっと見る</Button>
            </Box>
            <CardListView key="user" listData={newUser} listTitle="新着ユーザー" listDiscription="最近利用を開始したユーザ" type="account"></CardListView>




            {/**　============== タイトル系 廃止 11/18 ===============*/}

          </Box>
          {footerMenu()}
        </Box >
      </>


    );

  }




}
export default MainList;

const style = {
  // メニュー一覧グリッドのアイコンのデザイン
  iconStyle: {
    color: '#ffffff',
    fontSize: 100
  },
  // メニュー一覧グリッドの標題のデザイン
  iconCaption: {
    color: '#ffffff',
    fontWeight: 'bold'
  }
}

const useStyles = makeStyles((theme) => ({
  // 全体レイアウト　中心揃えにする
  contentWrapper: {
    margin: "0 auto",
    padding: 10,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',

  },
  // コンテンツの幅。端末ごとに広がりすぎるのを防ぐ。左揃え
  mainBox: {
    maxWidth: "1000px",
    width: '100%',
    margin: "0 auto",
  },
  // 個別コンテンツ　中央揃えで縦に内容を並べる
  centerBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  // 個別コンテンツ　横並びに内容を並べる
  leftBox: {
    display: 'flex',
    flexDirection: 'row',
    margin: "0 auto",
  },
  // タグの背景が白だと見えないため、グレーにする
  darkBackgroundBox: {
    padding: 10,
    borderRadius: 10,
    backgroundColor: "#070b13",
  },
  // グリッドメニューを入れる背景
  gridMenuBackground: {
    paddingBottom: 20,
    borderRadius: 10,
    backgroundColor: "#f6f6f6",

  },
  // グリッドのメニュー一覧の１枠の設定
  gridMenuGrid: {
    background: 'linear-gradient(to bottom right,#4fc3f7,#80deea)',
    borderRadius: 10,
    display: 'flex', // 内容のセンタリング
    flexDirection: 'column', // 内容のセンタリング
    alignItems: 'center', // 内容のセンタリング
    padding: 5,
    width: 150,
    height: 150,
    margin: "0 auto",// これでこの箱自体のセンタリング
    color: "#ffffff"
  },
  footerMenu: {
    position: 'fixed',
    bottom: 0,
    width: '100%',
    // 水平に均等割
    display: 'flex',
    justifyContent: 'space-around',
    backgroundColor: '#0099ff',
    opacity: 0.8

  },
}));

