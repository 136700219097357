import React, { useState, useEffect, useLayoutEffect, forwardRef, useRef, useCallback } from "react";
import { Timestamp } from 'firebase/firestore';

import { useNavigate, useParams, Navigate, Link, useLocation } from 'react-router-dom';
import { getAuth, deleteUser } from "firebase/auth";
import { useAuthContext } from '../../../AuthContext';
import IfProxy from '../../../IfProxy';
import IfProxyTool from '../../../IfProxyTool';
import { useQRCode } from 'next-qrcode';
import { isMobile } from "react-device-detect"


import HeadFooter from '../../HeadFooter';

// ReactGA Analytics
import ReactGA from "react-ga4";

// Material UI
import { makeStyles } from '@mui/styles';

// アニメーション
import { motion } from "motion/react"

// スピナーアニメーション
import { Dna } from 'react-loader-spinner'
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';

import Typography from '@mui/material/Typography';

// ショートかっと
import hotkeys from 'hotkeys-js';



const SetupTimer = (props) => {
    const auth = getAuth();
    const navigate = useNavigate();
    const { user } = useAuthContext();
    const classes = useStyles();
    const params = useParams();
    const location = useLocation();

    const { Canvas } = useQRCode();
    const [ready, setReady] = useState(false);
    const [loading, setLoading] = useState(true);


    let first = true;

    useLayoutEffect((props) => {



        // 【useEffectの２重処理について】
        // https://zenn.dev/takeharu/scraps/d14cf9d4239ec4
        // useEffectは２回描画される
        // mount -> unmount -> mount
        // 処理がこのようにダブルのは開発環境だけ
        if (first) {
            setLoading(true)

            // ReactGA Analytics ---
            ReactGA.initialize("G-2ZCXE08H2S");
            ReactGA.send({
                hitType: "pageview",
                // アクセスしたパス (pathname) とクエリ文字列 (search) を送付する (必要に応じて編集する)
                page: location.pathname + location.search,
            });
            loadData();

        }
        first = false;

        return () => {
        }

    }, []);



    const loadData = async () => {

        setLoading(false)

    }

    if (loading) {

    } else {



        return (<>

            <motion.div
                initial={{ scale: 1, opacity: 0 }}
                animate={{
                    x: [1000, -100, 0],
                    scale: 1, opacity: 1, transition: { duration: 0.2, ease: "easeInOut" }
                }}

            >


                <Box className={classes.centerBox} >

                    {!isMobile && <Box>
                        <h3>スマホに転送はこちら</h3>
                        <Canvas
                            text={window.location.href} // QRコードで表示される内容。省略できません。
                            options={{ // ここの要素は省略可能。ご利用は計画的に。
                                type: 'image/webp', // 出力フォーマット。 pngかjpegかwebpから選べる
                                quality: 0.95, // クオリティ。 0〜１で1に近い方が綺麗。
                                level: 'M', // 補正レベル。 low(L)、medium（Ｍ）、quartile(Ｑ)、high(H)から選べる。
                                margin: 1, // クワイエットゾーンの広さを決める。
                                scale: 4, // ドット1個につき何px使うかの指標。
                                width: 150, // 出力画像の幅の指定。(収まらなかったら無視する)
                                color: { // RGBAで色を決める。
                                    dark: '#0099ff',
                                    light: '#ffffff',
                                },
                            }}

                        />
                    </Box>
                    }

                    <motion.ui
                        whileHover={{ scale: 1.1 }}
                        whileTap={{
                            scale: 0.95,
                        }}
                        onHoverStart={() => console.log('hover started!')}>
                        <Button
                            sx={{
                                height: "100px", width: "400px", marginTop: 50
                            }}
                            variant="contained"
                            color="primary"
                            onClick={() => {
                                navigate('/v2/SetupTimer1');
                            }}>
                            始める
                        </Button>
                    </motion.ui>
                </Box >
            </motion.div >


        </>)
    }
}
export default SetupTimer;



const useStyles = makeStyles((theme) => ({

    // 全体レイアウト
    contentWrapper: {
        marginTop: 20,
        flexDirection: 'column',
        alignItems: 'center',
    },
    mainBox: {
        maxWidth: "1000px",
        margin: "0 auto",

    },

    // 個別コンテンツ　中央揃えで縦に内容を並べる
    centerBox: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        backgroundColor: 'white'
    },// 個別コンテンツ　中央揃えで横に内容を並べる
    centerFlatBox: {
        display: 'flex',
        flexDirection: 'row',
        margin: 10,
        alignItems: 'center',
    },
    // 個別コンテンツ　左揃えで縦に内容を並べる
    leftCenterBox: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'left',
    },
    // 個別コンテンツ　左揃えで縦に内容を並べる
    leftRowBox: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'left',
    },
    // 個別コンテンツ　横並びに内容を並べる
    leftBox: {
        display: 'flex',
        flexDirection: 'row',
        margin: "0 auto",
    },

}));