import React, { useState, useEffect, useLayoutEffect, forwardRef, useRef, useCallback } from "react";
import { Timestamp } from 'firebase/firestore';

import { useNavigate, useParams, Navigate, Link, useLocation } from 'react-router-dom';
import { getAuth, deleteUser } from "firebase/auth";
import { useAuthContext } from '../../../AuthContext';
import IfProxy from '../../../IfProxy';
import IfProxyTool from '../../../IfProxyTool';
import { useQRCode } from 'next-qrcode';
import { isMobile } from "react-device-detect"


import HeadFooter from '../../HeadFooter';

// ReactGA Analytics
import ReactGA from "react-ga4";

// Material UI
import { makeStyles } from '@mui/styles';

// スピナーアニメーション
import { Dna } from 'react-loader-spinner'
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';

import Typography from '@mui/material/Typography';

// ショートかっと
import hotkeys from 'hotkeys-js';



const SetupTimer1 = (props) => {
    const auth = getAuth();
    const navigate = useNavigate();
    const { user } = useAuthContext();
    const classes = useStyles();
    const params = useParams();
    const location = useLocation();

    const { Canvas } = useQRCode();
    const [ready, setReady] = useState(false);
    const [loading, setLoading] = useState(true);


    let first = true;

    useLayoutEffect((props) => {



        // 【useEffectの２重処理について】
        // https://zenn.dev/takeharu/scraps/d14cf9d4239ec4
        // useEffectは２回描画される
        // mount -> unmount -> mount
        // 処理がこのようにダブルのは開発環境だけ
        if (first) {
            setLoading(true)

            // ReactGA Analytics ---
            ReactGA.initialize("G-2ZCXE08H2S");
            ReactGA.send({
                hitType: "pageview",
                // アクセスしたパス (pathname) とクエリ文字列 (search) を送付する (必要に応じて編集する)
                page: location.pathname + location.search,
            });
            loadData();

        }
        first = false;

        return () => {
        }

    }, []);



    const loadData = async () => {

        setLoading(false)


    }

    if (loading) {

    } else {

        if (user) {
            navigate('/v2/SetupTimer2');
        }

        return (<>
            <Box className={classes.centerBox} >

                <h1>ログインしますか？</h1>
                <p></p>
                TODO ログイン、一時的にログイン画面に飛ばし、終わったら、次に戻す。twitterログインの場合、どうあがいても何のパラメータもなしにメイン画面にいってしまう
                <Button
                    sx={{ height: "100px", margin: "50px", width: "80%" }}
                    variant="contained"
                    color="primary"
                    onClick={() => {
                        navigate('/login?fromPage=SetupTimer');
                    }}>
                    ログインする
                </Button>
                <Button
                    sx={{ height: "100px", margin: "50px", width: "80%" }}
                    variant="outlined"
                    color="primary"
                    onClick={() => {
                        navigate('/v2/SetupTimer2');
                    }}>
                    ログインせず利用する<br></br>結果を保存することができません
                </Button>
            </Box>


        </>)
    }
}
export default SetupTimer1;



const useStyles = makeStyles((theme) => ({

    // 全体レイアウト
    contentWrapper: {
        marginTop: 20,
        flexDirection: 'column',
        alignItems: 'center',
    },
    mainBox: {
        maxWidth: "1000px",
        margin: "0 auto",

    },

    // 個別コンテンツ　中央揃えで縦に内容を並べる
    centerBox: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        backgroundColor: 'white'
    },// 個別コンテンツ　中央揃えで横に内容を並べる
    centerFlatBox: {
        display: 'flex',
        flexDirection: 'row',
        margin: 10,
        alignItems: 'center',
    },
    // 個別コンテンツ　左揃えで縦に内容を並べる
    leftCenterBox: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'left',
    },
    // 個別コンテンツ　左揃えで縦に内容を並べる
    leftRowBox: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'left',
    },
    // 個別コンテンツ　横並びに内容を並べる
    leftBox: {
        display: 'flex',
        flexDirection: 'row',
        margin: "0 auto",
    },

}));