import React, { useState, useEffect, useLayoutEffect, forwardRef, useRef, useCallback } from "react";
import { useNavigate, useParams, Navigate, Link, useLocation } from 'react-router-dom';
import { getAuth, deleteUser } from "firebase/auth";
import { useAuthContext } from '../../../AuthContext';
import IfProxy from '../../../IfProxy';
import IfProxyTool from '../../../IfProxyTool';


import HeadFooter from '../../HeadFooter';

// ReactGA Analytics
import ReactGA from "react-ga4";

// Material UI
import { makeStyles } from '@mui/styles';

// スピナーアニメーション
import { Dna } from 'react-loader-spinner'
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';

// アニメーション
import { motion, useAnimation } from "motion/react"

import Typography from '@mui/material/Typography';

//graph
import StopWatch from "../../../common/graphs/StopWatch";
import { Scale } from "victory";

import DisplayAd from "../../../common/adsence/DisplayAd"


// ショートかっと
import hotkeys from 'hotkeys-js';
const OnedroTimer = (props) => {
    const auth = getAuth();
    const navigate = useNavigate();
    const { user } = useAuthContext();
    const classes = useStyles();
    const params = useParams();
    const location = useLocation();
    const ifProxy = new IfProxy();
    const ifProxyTool = new IfProxyTool();

    const useAnimationControll = useAnimation()
    const useAnimationControll_changeMode = useAnimation()

    const [started, setStarted] = useState(false);
    const [completed, setCompleted] = useState(true);



    const [loading, setLoading] = useState(true);
    // RecordConfig
    const [recordConfig, setRecordConfig] = useState(false); //表示している１つのコンフィグ
    const [recordConfigList, setRecordConfigList] = useState(false); //ユーザで取得できたすべてのコンフィグ
    const [selectedConfig, setSelectedConfig] = useState(0); //今表示したいコンフィグ

    // ワンドロ中のメモを持つ配列 Diaryに登録される
    const [aOnedroNote, setAOnedroNote] = useState([]); // ワンドロノート フォームの文字を持つ
    const [onedroNote, setOnedroNote] = useState([]); // ワンドロノート


    // ショートカット
    const shortCat = () => {
        hotkeys('5', function (event, handler) {
            console.log("shortCat======")
            event.preventDefault()
            alert('you pressed F5!')
        });
    }

    const unregisterrHotkeys = () => {
        hotkeys.unbind('5');
    }


    let first = true;

    useLayoutEffect((props) => {

        shortCat();

        // 【useEffectの２重処理について】
        // https://zenn.dev/takeharu/scraps/d14cf9d4239ec4
        // useEffectは２回描画される
        // mount -> unmount -> mount
        // 処理がこのようにダブルのは開発環境だけ
        if (first) {
            setLoading(true)
            // ReactGA Analytics ---
            ReactGA.initialize("G-2ZCXE08H2S");
            ReactGA.send({
                hitType: "pageview",
                // アクセスしたパス (pathname) とクエリ文字列 (search) を送付する (必要に応じて編集する)
                page: location.pathname + location.search,
            });
            ReactGA.event("ワンドロタイマーを利用した");
            // ------
            loadData();
        }

        //    console.log(first ? "１回目である" : "２回目である")
        //    console.log("回数評価処理は以下")
        //    console.log("開発環境２重処理防止のため、１回目、２回目判定フラグ")
        //    console.log(first)
        first = false;
        //    console.log("２重登録判定フラグ、更新後")
        //    console.log(first)

        return () => {
            //      console.log("UNMOUNT 開発環境２重処理防止のため、１回目、２回目判定フラグ")
            //      console.log(first)
            unregisterrHotkeys()
        }

    }, []);

    const intervalRef = useRef(null);
    //const start = useCallback(() => {
    const start = () => {
        if (intervalRef.current !== null) {
            return;
        }
        setStarted(true)
        setCompleted(false)
        setNowTimeDt(new Date())
        setTargets()
        // タイマースタート
        intervalRef.current = setInterval(() => {
            setNowTimeDt(new Date())
        }, 1000);
        useAnimationControll.start({
            x: [200, -20, 0],
            scale: [0.7, 1], opacity: [0, 1], transition: { duration: 0.5, ease: "easeOut" }
        });
    };
    const stop = useCallback(() => {
        if (intervalRef.current === null) {
            return;
        }
        clearInterval(intervalRef.current);
        intervalRef.current = null;
    }, []);

    const loadData = async () => {
        let recordConfigData = []
        if (user) {
            recordConfigData = await ifProxyTool.getRecordConfig(user.uid);
        }


        if (recordConfigData.length > 0) {
            console.log("=======-")
            console.log(recordConfigData)
            console.log(recordConfigData[selectedConfig])

            setRecordConfigList(recordConfigData)
            setRecordConfig(recordConfigData[selectedConfig]);

        } else {
            setRecordConfig({
                id: "",
                uid: user?.uid,
                step1Name: "ラフ",
                step2Name: "ペン入れ",
                step3Name: "塗り",
                step4Name: "仕上げ",
                step5Name: "",
                step6Name: "",
                step7Name: "",
                step8Name: "",
                step9Name: "",
                step10Name: "",
                step1Time: 10,
                step2Time: 15,
                step3Time: 30,
                step4Time: 5,
                step5Time: null,
                step6Time: null,
                step7Time: null,
                step8Time: null,
                step9Time: null,
                step10Time: null,
                memoForNext: null,
                memo: "",
            });

        }
        setLoading(false)
    }

    const toResult = async () => {
        // TODO Contextでよくない？
        const userData = await ifProxy.getUserData(user.uid)

        const recordDataParam = {
            step0Time: startDt,
            step1: recordConfig.step1Name,
            step1Time: resultStepDt1,
            step1FixCount: end1Fixcount,
            step2: recordConfig.step2Name,
            step2Time: resultStepDt2,
            step2FixCount: end2Fixcount,
            step3: recordConfig.step3Name,
            step3Time: resultStepDt3,
            step3FixCount: end3Fixcount,
            step4: recordConfig.step4Name,
            step4Time: resultStepDt4,
            step4FixCount: end4Fixcount,
            step5: recordConfig.step5Name,
            step5Time: resultStepDt5,
            step5FixCount: end5Fixcount,
            step6: recordConfig.step6Name,
            step6Time: resultStepDt6,
            step6FixCount: end6Fixcount,
            step7: recordConfig.step7Name,
            step7Time: resultStepDt7,
            step7FixCount: end7Fixcount,
            step8: recordConfig.step8Name,
            step8Time: resultStepDt8,
            step8FixCount: end8Fixcount,
            step9: recordConfig.step9Name,
            step9Time: resultStepDt9,
            step9FixCount: end9Fixcount,
            step10: recordConfig.step10Name,
            step10Time: resultStepDt10,
            step10FixCount: end10Fixcount,
            onedroNote,
            completeTime: new Date(),
            uid: userData.uid,
        }
        const setting = {
            step1Name: recordConfig.step1Name,
            step2Name: recordConfig.step2Name,
            step3Name: recordConfig.step3Name,
            step4Name: recordConfig.step4Name,
            step5Name: recordConfig.step5Name,
            step6Name: recordConfig.step6Name,
            step7Name: recordConfig.step7Name,
            step8Name: recordConfig.step8Name,
            step9Name: recordConfig.step9Name,
            step10Name: recordConfig.step10Name,
            step1Time: recordConfig.step1Time,
            step2Time: recordConfig.step2Time,
            step3Time: recordConfig.step3Time,
            step4Time: recordConfig.step4Time,
            step5Time: recordConfig.step5Time,
            step6Time: recordConfig.step6Time,
            step7Time: recordConfig.step7Time,
            step8Time: recordConfig.step8Time,
            step9Time: recordConfig.step9Time,
            step10Time: recordConfig.step10Time,
            configName: recordConfig.configName
        }
        navigate('/v2/Result', { state: { userData, recordDataParam, setting } })
    }

    /**
 * nullは0にする。文字は数値にする
 * @param {*} param 
 * @returns 
 */
    const psi = ((param) => {
        return param - 0
    })

    //目標合計所要分 
    const targetMin_result = psi(recordConfig.step1Time) + psi(recordConfig.step2Time) + psi(recordConfig.step3Time) + psi(recordConfig.step4Time) + psi(recordConfig.step5Time) + psi(recordConfig.step6Time) + psi(recordConfig.step7Time) + psi(recordConfig.step8Time) + psi(recordConfig.step9Time) + psi(recordConfig.step10Time)

    // 現在ステップ
    const [stepNo, setStepNo] = useState(); // 現在時刻


    const [nowTimeDt, setNowTimeDt] = useState(); // 現在時刻
    const [nowStepName, setNowStepName] = useState("開始前"); // 現在のステップ名
    const [startDt, setStartDt] = useState(); //開始時刻
    const [stepStartDt, setStepStartDt] = useState(); //直前の工程の完了時刻
    const [nowStepTargetDt, setNowStepTargetDt] = useState(); //現在のステップの目標時間
    const [nowStepTarget, setNowStepTarget] = useState(); //現在のステップの目標分 時間枠

    // 完了時刻
    const [resultStepDt1, setResultStepDt1] = useState(null);
    const [resultStepDt2, setResultStepDt2] = useState(null);
    const [resultStepDt3, setResultStepDt3] = useState(null);
    const [resultStepDt4, setResultStepDt4] = useState(null);
    const [resultStepDt5, setResultStepDt5] = useState(null);
    const [resultStepDt6, setResultStepDt6] = useState(null);
    const [resultStepDt7, setResultStepDt7] = useState(null);
    const [resultStepDt8, setResultStepDt8] = useState(null);
    const [resultStepDt9, setResultStepDt9] = useState(null);
    const [resultStepDt10, setResultStepDt10] = useState(null);
    const [endEstimatedDatetime, setEndEstimatedDatetime] = useState(null);

    // 戻って修正した回数 // はいし。一旦0を全て入れる
    const [end1Fixcount, setEnd1Fixcount] = useState(0);
    const [end2Fixcount, setEnd2Fixcount] = useState(0);
    const [end3Fixcount, setEnd3Fixcount] = useState(0);
    const [end4Fixcount, setEnd4Fixcount] = useState(0);
    const [end5Fixcount, setEnd5Fixcount] = useState(0);
    const [end6Fixcount, setEnd6Fixcount] = useState(0);
    const [end7Fixcount, setEnd7Fixcount] = useState(0);
    const [end8Fixcount, setEnd8Fixcount] = useState(0);
    const [end9Fixcount, setEnd9Fixcount] = useState(0);
    const [end10Fixcount, setEnd10Fixcount] = useState(0);
    /**
     * 　
     */
    const setTargets = () => {
        setStepNo(1)
        let date = new Date()
        setStartDt(date)
        setStepStartDt(date) //初回は開始時刻と同じ
        let dateStepTime = new Date()
        const log = dateStepTime.setMinutes(dateStepTime.getMinutes() + recordConfig.step1Time)
        setNowStepTarget(log)
        setNowStepTarget(recordConfig.step1Time)
        setNowStepName(recordConfig.step1Name)

    }

    const setStateStep = (no, name, stepTarget) => {
        let date = new Date()
        let dateStepTime = new Date()
        setStepNo(no) //次に入るステップ
        setNowStepName(name)  //次に入るステップ名
        setStepStartDt(date) // 前回ステップの終了時間（このボタンを押した時）９
        setNowStepTargetDt(dateStepTime.setMinutes(dateStepTime.getMinutes() + stepTarget))// 目標分＋スタート時刻
        setNowStepTarget(stepTarget) // ステップの目標分
    }
    /**
     * 
     */
    const goNext = () => {
        useAnimationControll.start({
            x: [200, -20, 0],
            scale: [0.7, 1], opacity: [0, 1], transition: { duration: 0.5, ease: "easeOut" }
        });
        // stepNoは直前のステップが入る
        switch (stepNo) {
            case 1: setStateStep(2, recordConfig.step2Name, recordConfig.step2Time)  //step2に入る時の初期化
                setResultStepDt1(new Date())
                break;
            case 2: setStateStep(3, recordConfig.step3Name, recordConfig.step3Time) //step3に入る時の初期化
                setResultStepDt2(new Date())

                break;
            case 3: setStateStep(4, recordConfig.step4Name, recordConfig.step4Time) //step4に入る時の初期化
                setResultStepDt3(new Date())
                break;
            case 4: setStateStep(5, recordConfig.step5Name, recordConfig.step5Time) //step5に入る時の初期化
                setResultStepDt4(new Date())
                break;
            case 5: setStateStep(6, recordConfig.step6Name, recordConfig.step6Time) //step6に入る時の初期化
                setResultStepDt5(new Date())
                break;
            case 6: setStateStep(7, recordConfig.step7Name, recordConfig.step7Time) //step7に入る時の初期化
                setResultStepDt6(new Date())
                break;
            case 7: setStateStep(8, recordConfig.step8Name, recordConfig.step8Time) //step8に入る時の初期化
                setResultStepDt7(new Date())
                break;
            case 8: setStateStep(9, recordConfig.step9Name, recordConfig.step9Time) //step9に入る時の初期化
                setResultStepDt8(new Date())
                break;
            case 9: setStateStep(10, recordConfig.step10Name, recordConfig.step10Time) //step10に入る時の初期化
                setResultStepDt9(new Date())
                break;
            case 10:
                setResultStepDt10(new Date())
                break;

            default:
                break;

        }


    }/**
     * 
     */
    const conplete = () => {
        let result = window.confirm("すべての工程が完了し、タイマーストップしますか？")
        if (result) {
            setCompleted(true)
            stop()
            setEndEstimatedDatetime(new Date())
        }

    }

    if (loading) {

        return (
            <>
                読み込み中
            </>
        )
    } else {

        return (<motion.div
            initial={{ scale: 1, opacity: 0 }}
            animate={{
                y: [-1000, 100, 0],
                scale: 1, opacity: 1, transition: { duration: 0.2, ease: "easeInOut" }
            }}>
            <Button
                color='warning'
                variant="contained"
                disabled={!user}
                onClick={() => {
                    navigate('/v2/Config')

                }}>
                タイマー設定変更{!user ? "【要ログイン】" : ""}
            </Button>
            <motion.div
                animate={useAnimationControll_changeMode}>
                選択中のタイマー:{recordConfig.configName}
            </motion.div>
            {/* ----- タイマーの予定時間選択メニュ ------ */}
            {recordConfigList[0] && <Button
                color='warning'
                variant="outlined"
                disabled={!user}
                onClick={() => {
                    setSelectedConfig(0)
                    setRecordConfig(recordConfigList[0]);
                    useAnimationControll_changeMode.start({
                        x: [-3, 5, 0],
                        opacity: [0, 1], transition: { duration: 0.5, ease: "easeInOut" }
                    });
                }}>
                {recordConfigList[0].configName}
            </Button>}
            {recordConfigList[1] && <Button
                color='warning'
                variant="outlined"
                disabled={!user}
                onClick={() => {
                    setSelectedConfig(1)
                    setRecordConfig(recordConfigList[1]);
                    useAnimationControll_changeMode.start({
                        x: [-5, 5, 0],
                        opacity: [0, 1], transition: { duration: 0.5, ease: "easeInOut" }
                    });
                }}>
                {recordConfigList[1].configName}
            </Button>}
            {recordConfigList[2] && <Button
                color='warning'
                variant="outlined"
                disabled={!user}
                onClick={() => {
                    setSelectedConfig(2)
                    setRecordConfig(recordConfigList[2]);
                    useAnimationControll_changeMode.start({
                        x: [-5, 5, 0],
                        opacity: [0, 1], transition: { duration: 0.5, ease: "easeInOut" }
                    });
                }}>
                {recordConfigList[2].configName}
            </Button>}
            <br></br>
            <motion.div
                animate={useAnimationControll_changeMode}>
                {recordConfig.step1Name} : {recordConfig.step1Time} → {recordConfig.step2Name} : {recordConfig.step2Time} → {recordConfig.step3Name} : {recordConfig.step3Time}→ {recordConfig.step4Name} : {recordConfig.step4Time}→ {recordConfig.step5Name} : {recordConfig.step5Time}→ {recordConfig.step6Name} : {recordConfig.step6Time}→ {recordConfig.step7Name} : {recordConfig.step7Time}
                → {recordConfig.step8Name} : {recordConfig.step8Time}→ {recordConfig.step9Name} : {recordConfig.step9Time}→ {recordConfig.step10Name} : {recordConfig.step10Time}→ 完了({targetMin_result}分)
            </motion.div>
            {/* ----- タイマーの予定時間選択メニュ ここまで ------ */}


            <motion.div
                animate={useAnimationControll}
            >
                <h1>現在の工程　{stepNo} : {nowStepName}</h1>
            </motion.div>
            <Box className={classes.centerFlatBox}>
                <Button sx={{ backgroundColor: !started ? 'blue' : 'gray', height: '70px' }} fullWidth disabled={started} size="large" onClick={() => start()}><Typography color={!started ? 'white' : 'darkgray'} fontSize={30}>スタート</Typography></Button>
                <Button sx={{ backgroundColor: !completed ? 'blue' : 'gray', height: '70px' }} fullWidth disabled={completed} onClick={() => goNext()}><Typography color={!completed ? 'white' : 'darkgray'} fontSize={30}>{nowStepName}完了 次へ</Typography></Button>
                <Button sx={{ backgroundColor: !completed ? 'red' : 'gray', height: '70px' }} fullWidth disabled={completed} onClick={() => conplete()}><Typography color={!completed ? 'white' : 'darkgray'} fontSize={50}>完了</Typography></Button>
            </Box>


            <Grid container spacing={0}>
                <Grid item xs={12} md={6}>
                    <motion.div
                        animate={useAnimationControll}
                    >
                        <h1>{nowStepName} の残り時間</h1>
                        {!nowTimeDt && <h2><font color="gray">スタートボタンで計測を開始します</font></h2>}
                        {nowTimeDt && <StopWatch key="stopWatch2" maxTime={nowStepTarget} startTime={stepStartDt} nowTime={nowTimeDt} />}
                    </motion.div>
                </Grid>
                <Grid item xs={12} md={6}>
                    <h1>全体タイム</h1>
                    {!nowTimeDt && <h2><font color="gray">スタートボタンで計測を開始します</font></h2>}
                    {nowTimeDt && <StopWatch key="stopWatch1" maxTime={targetMin_result} startTime={startDt} nowTime={nowTimeDt} />}
                </Grid>

            </Grid>
            <hr></hr>

            <Box className={classes.centerBox}>
                <Typography variant="h5" color="secondary"
                >ノート 作業中に思いついた改善アイデアをメモ</Typography>
                <ul>
                    {onedroNote.map(v => <li>対象ステップ ({v.stepName}) : {v.text}</li>)}
                </ul>

                <TextField margin="nomal" id="filled-basic" size="small"
                    fullWidth
                    onChange={(event) => {
                        setAOnedroNote(() => event.target.value)
                    }} variant="outlined" type="string" value={aOnedroNote}
                />
                <Button
                    variant="outlined"
                    color="secondary"
                    sx={{ height: "50px", width: "80%" }}
                    onClick={() => {
                        setOnedroNote((pre) => {
                            console.log(pre)
                            let list = pre;
                            list.push({ stepNo: stepNo, stepName: nowStepName, text: aOnedroNote })
                            return list
                        })
                        setAOnedroNote("")

                    }}>メモを追加</Button>
            </Box>
            <hr></hr>
            <Box className={classes.centerBox}>
                <Button
                    sx={{ height: "100px", width: "80%" }}
                    disabled={!endEstimatedDatetime || !user}
                    variant="contained"
                    color="primary"
                    onClick={() => {
                        toResult();
                    }}>
                    結果を見る
                </Button>

                <Button
                    variant="contained"
                    sx={{ height: "100px", width: "80%" }}
                    color="error"
                    onClick={() => {
                        let result = window.confirm("保存せず終了します")
                        if (result) {
                            setLoading(true)
                            navigate('/')
                        }
                    }}>
                    中断
                </Button>
            </Box>

        </motion.div >)
    }

}
export default OnedroTimer;



const style = {
    scoreLabel: {
        fontSize: 25,

    },
}

const useStyles = makeStyles((theme) => ({


    // 個別コンテンツ　中央揃えで縦に内容を並べる
    centerBox: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },// 個別コンテンツ　中央揃えで横に内容を並べる
    centerFlatBox: {
        display: 'flex',
        flexDirection: 'row',
        margin: 10,
        alignItems: 'center',
    },
    // 個別コンテンツ　左揃えで縦に内容を並べる
    leftCenterBox: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'left',
    },
    // 個別コンテンツ　左揃えで縦に内容を並べる
    leftRowBox: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'left',
    },
    // 個別コンテンツ　横並びに内容を並べる
    leftBox: {
        display: 'flex',
        flexDirection: 'row',
        margin: "0 auto",
    },

    //サンプルのグラフエリア
    sampleGraphEria: {
        width: 200
    },

    cardList: {
    },
    card: {
    },
    bottomCard: {
        width: 250
    },
}));