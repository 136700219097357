import logo from './logo.svg';
import './App.css';
import ifProxy from './IfProxy.js';
import { AuthProvider } from './AuthContext.js';
import React, { Component } from 'react';
import { Routes, Route, Link, BrowserRouter } from "react-router-dom";

import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Button from '@mui/material/Button';
import { green, purple } from '@mui/material/colors';

// 各ページ
import Lp from './pages/LpAndGuide/Lp.js';
import LpTimer from './pages/LpAndGuide/LpTimer.js';

import RuleAndManner from './pages/LpAndGuide/RuleAndManner.js';
import CreatorGuide from './pages/LpAndGuide/CreatorGuide.js';
import ManualStripe from './pages/LpAndGuide/ManualStripe.js';
import Kiyaku from './pages/LpAndGuide/Kiyaku.js';
import Privacypolicy from './pages/LpAndGuide/Privacypolicy.js';
import Tokushoho from './pages/LpAndGuide/Tokushoho.js';


import Notfound from './pages/Notfound.js';


import Login from './pages/Login/Login.js';
import UserPopLogin from './pages/Login/UserPopLogin.js';

import Start from './pages/HomeAndInsentive/Start.js';

import MainList from './pages/HomeAndInsentive/MainList.js';
import SearchListView from './pages/HomeAndInsentive/SearchListView.js';
import FollowList from './pages/HomeAndInsentive/FollowList.js';
import KikakuRank from './pages/HomeAndInsentive/KikakuRank.js';

// Calender
import OnedroCalender from './pages/Calender/OnedroCalender.js';




import NotificationList from './pages/HomeAndInsentive/NotificationList.js';

import User from './pages/User/User.js';
import UserPop from './pages/User/UserPop.js';

import UserDashbord from './pages/User/UserDashbord.js';
import UserSubscribe from './pages/User/UserSubscribe.js';


import UserEdit from './pages/User/UserEdit.js';
import UserEditFirst from './pages/User/UserEditFirst.js';
import StraitLineSignup from './pages/User/StraitLineSignup.js';


// Business
import CreateRequest from './pages/Busyness/CreateRequest.js';
import Request from './pages/Busyness/Request.js';
import CreateProduct from './pages/Busyness/CreateProduct.js';
import Product from './pages/Busyness/Product.js';
import PortforioProduct from './pages/Busyness/PortforioProduct.js';

import CreateLimitedRequest from './pages/BusynessLimitedRequest/CreateLimitedRequest.js';
import LimitedRequest from './pages/BusynessLimitedRequest/LimitedRequest.js';



// お題
import Odai from './pages/Busyness/Odai.js';
import CreateOdai from './pages/Busyness/CreateOdai.js';
import OdaiTeianList from './pages/Busyness/OdaiTeianList.js';

// 日記とワンドロタイマー
import Diary from './pages/Tool/Diary.js'; //日記作成画面
import DiaryView from './pages/Tool/DiaryView.js'; //日記閲覧画面
import DiaryList from './pages/Tool/DiaryList.js';
import OnedroTimer from './pages/Tool/OnedroTimer.js';
import OnedroTimerV2 from './pages/Tool/v2/OnedroTimer.js';
import OnedroTimerCountup from './pages/Tool/v2/OnedroTimerCountup.js';

import Config from './pages/Tool/v2/Config.js';
import Result from './pages/Tool/v2/Result.js';
import SetupTimer from './pages/Tool/v2/SetupTimer.js';
import SetupTimer1 from './pages/Tool/v2/SetupTimer_1.js';
import SetupTimer2 from './pages/Tool/v2/SetupTimer_2.js';
import SetupTimer3 from './pages/Tool/v2/SetupTimer_3.js';




// チュートリアル
import Tu from './pages/Tu/Tu.js';
import GuidTu1 from './pages/Tu/GuidTu1.js';
import GuidTu4 from './pages/Tu/GuidTu4.js';
import GuidTu6 from './pages/Tu/GuidTu6.js';
import GuidTu8 from './pages/Tu/GuidTu8.js';
import GuidTu16 from './pages/Tu/GuidTu16.js';





import CreatePortforioProduct from './pages/Busyness/CreatePortforioProduct.js';


import Management from './pages/User/Management.js';
import ApprovalList from './pages/E/ApprovalList.js';
import ApprovalCommentList from './pages/E/ApprovalCommentList.js';
import Standard from './pages/StandardPage.js';


/** 全体のカラーテーマ */
const mainTheme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      light: '#757ce8',
      main: '#0099ff',
      dark: '#002884',
      contrastText: '#fff',
    },
    secondary: {
      main: '#4682b4',
      contrastText: '#fff',
    },
  },
});

function App() {
  return (
    <ThemeProvider theme={mainTheme}>
      <AuthProvider>
        <BrowserRouter>
          <Routes>
            <Route path="/Standard" element={<Standard />} />
            <Route path="/Lp" element={<Lp />} />
            <Route path="/LpTimer" element={<LpTimer />} />

            <Route path="/ManualStripe" element={<ManualStripe />} />
            <Route path="/Kiyaku" element={<Kiyaku />} />
            <Route path="/Privacypolicy" element={<Privacypolicy />} />
            <Route path="/Tokushoho" element={<Tokushoho />} />


            <Route path="/login" element={<Login />} />
            <Route path="/UserPopLogin" element={<UserPopLogin />} />


            <Route path="/" element={<Start />} />
            <Route path="/MainList" element={<MainList />} />
            <Route path="/SearchListView/:id" element={<SearchListView />} />
            <Route path="/SearchListView" element={<SearchListView />} />
            <Route path="/FollowList" element={<FollowList />} />
            <Route path="/KikakuRank/:id" element={<KikakuRank />} />

            <Route path="/User/:id" element={<User />} />
            <Route path="/UserDashbord/:id" element={<UserDashbord />} />
            <Route path="/UserDashbord" element={<UserDashbord />} />

            <Route path="/UserSubscribe" element={<UserSubscribe />} />


            <Route path="/UserEdit/:id" element={<UserEdit />} />
            <Route path="/UserEditFirst/:id" element={<UserEditFirst />} />
            <Route path="/NotificationList/" element={<NotificationList />} />
            <Route path="/StraitLineSignup/" element={<StraitLineSignup />} />
            <Route path="/UserPop/:id" element={<UserPop />} />
            <Route path="/UserPop/:id/:landing" element={<UserPop />} />





            {/** リクエスト機能 Busyness */}
            <Route path="/CreateRequest/:toUid" element={<CreateRequest />} />
            <Route path="/CreateRequest/:toUid/:odaiid" element={<CreateRequest />} />

            <Route path="/Request/:id" element={<Request />} />
            <Route path="/CreateProduct/:id" element={<CreateProduct />} />
            <Route path="/Product/:id" element={<Product />} />
            <Route path="/CreateOdai" element={<CreateOdai />} />
            <Route path="/OdaiTeianList/:id" element={<OdaiTeianList />} />

            <Route path="/Odai" element={<Odai />} />

            {/** 限定リクエスト機能 Busyness */}
            <Route path="/CreateLimitedRequest/:id" element={<CreateLimitedRequest />} />
            <Route path="/LimitedRequest/:id" element={<LimitedRequest />} />



            {/** ツール */}
            <Route path="/v2/SetupTimer" element={<SetupTimer />} />
            <Route path="/v2/SetupTimer1" element={<SetupTimer1 />} />
            <Route path="/v2/SetupTimer2" element={<SetupTimer2 />} />
            <Route path="/v2/SetupTimer3" element={<SetupTimer3 />} />

            <Route path="/OnedroTimer" element={<OnedroTimer />} />
            <Route path="/v2/OnedroTimer" element={<OnedroTimerV2 />} />
            <Route path="/v2/OnedroTimerCountup" element={<OnedroTimerCountup />} />

            <Route path="/v2/Config" element={<Config />} />
            <Route path="/v2/Result" element={<Result />} />



            <Route path="/DiaryList" element={<DiaryList />} />
            <Route path="/Diary/:id" element={<Diary />} />
            <Route path="/DiaryView/:id" element={<DiaryView />} />

            {/** チュートリアル */}
            <Route path="/Tu" element={<Tu />} />
            <Route path="/GuidTu1" element={<GuidTu1 />} />
            <Route path="/GuidTu4" element={<GuidTu4 />} />
            <Route path="/GuidTu6" element={<GuidTu6 />} />
            <Route path="/GuidTu8" element={<GuidTu8 />} />
            <Route path="/GuidTu16" element={<GuidTu16 />} />



            <Route path="/RuleAndManner" element={<RuleAndManner />} />
            <Route path="/CreatorGuide" element={<CreatorGuide />} />

            <Route path="/CreatePortforioProduct/:id" element={<CreatePortforioProduct />} />
            <Route path="/PortforioProduct/:id" element={<PortforioProduct />} />


            <Route path="/Management" element={<Management />} />
            <Route path="/ApprovalList" element={<ApprovalList />} />
            <Route path="/ApprovalCommentList/:id" element={<ApprovalCommentList />} />
            <Route path="*" element={<Notfound />} />

            <Route path="/OnedroCalender" element={<OnedroCalender />} />



          </Routes>
        </BrowserRouter>
      </AuthProvider>
    </ThemeProvider>
  );
}

export default App;
