import React, { useState, useEffect, useLayoutEffect, forwardRef, useRef, useCallback } from "react";
import { Timestamp } from 'firebase/firestore';

import { useNavigate, useParams, Navigate, Link, useLocation } from 'react-router-dom';
import { getAuth, deleteUser } from "firebase/auth";
import { useAuthContext } from '../../../AuthContext';
import IfProxy from '../../../IfProxy';
import IfProxyTool from '../../../IfProxyTool';
import { useQRCode } from 'next-qrcode';
import { isMobile } from "react-device-detect"


import HeadFooter from '../../HeadFooter';

// ReactGA Analytics
import ReactGA from "react-ga4";

// Material UI
import { makeStyles } from '@mui/styles';

// アニメーション
import { motion } from "motion/react"

// スピナーアニメーション
import { Dna } from 'react-loader-spinner'
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';

import Typography from '@mui/material/Typography';

// ショートかっと
import hotkeys from 'hotkeys-js';



const SetupTimer3 = (props) => {
    const auth = getAuth();
    const navigate = useNavigate();
    const { user, userData } = useAuthContext();
    const classes = useStyles();
    const params = useParams();
    const location = useLocation();
    const ifProxy = new IfProxy();

    const { Canvas } = useQRCode();
    const [ready, setReady] = useState(false);
    const [loading, setLoading] = useState(true);
    const [premium, setPremium] = useState(false);
    const [stripeUserEnabled, setStripeUserEnabled] = useState(0); //注意。現在、無料限定リクエストの開放フラグとして管理中


    let first = true;

    useLayoutEffect((props) => {



        // 【useEffectの２重処理について】
        // https://zenn.dev/takeharu/scraps/d14cf9d4239ec4
        // useEffectは２回描画される
        // mount -> unmount -> mount
        // 処理がこのようにダブルのは開発環境だけ
        if (first) {
            setLoading(true)

            // ReactGA Analytics ---
            ReactGA.initialize("G-2ZCXE08H2S");
            ReactGA.send({
                hitType: "pageview",
                // アクセスしたパス (pathname) とクエリ文字列 (search) を送付する (必要に応じて編集する)
                page: location.pathname + location.search,
            });
            loadData();
        }
        first = false;

        return () => {
        }

    }, []);

    /**
   * ログイン済みユーザの場合はクリエイターアカウントであるかどうかをチェック
   */
    const userStatusCheck = async () => {

        if (!user) {
            // サインアップしてない
            setStripeUserEnabled(0)
        } else {
            const userData = await ifProxy.getUserData(user.uid);

            if (userData.remitedCreator) {

                // クリエイター登録ずみ
                setStripeUserEnabled(2)
            } else {
                // クリエイター登録をしていない
                setStripeUserEnabled(1)
            }
            // プレミアムチェック ２はプレミアム
            if (userData.premium == 2) {
                setPremium(true)
            } else if (userData.monitor) {
                // モニターへの特別解放
                setPremium(true)
                // 許可はFirebaseからの手動登録
            }



        }
    }



    const loadData = async () => {
        userStatusCheck();
        setLoading(false);

    }

    if (loading) {

    } else {



        return (<>
            <motion.div
                initial={{ scale: 1, opacity: 0 }}
                animate={{
                    x: [1000, -100, 0],
                    scale: 1, opacity: 1, transition: { duration: 0.2, ease: "easeInOut" }
                }}

            >
                <Box className={classes.centerBox} >

                    <h1>バージョン</h1>
                    <p></p>
                    <motion.ui
                        whileHover={{ scale: 1.1 }}
                        whileTap={{
                            scale: 0.95,
                        }}>
                        <Button
                            sx={{ height: "100px", margin: "50px", width: "500px" }}
                            variant="contained"
                            color="secondary"
                            onClick={() => {
                                navigate('/OnedroTimer');
                            }}>
                            通常モード
                        </Button>
                    </motion.ui>
                    <motion.ui
                        whileHover={{ scale: 1.1 }}
                        whileTap={{
                            scale: 0.95,
                        }}>
                        <Button
                            sx={{ height: "100px", margin: "50px", width: "500px" }}

                            variant="contained"
                            color="primary"
                            onClick={() => {
                                navigate('/v2/OnedroTimer');
                            }}>
                            v２タイマー
                        </Button>
                    </motion.ui>
                    ※ プレミアムユーザのみ利用可能です

                    <Button
                        sx={{ height: "10px", margin: "10px", width: "80%" }}
                        color="primary"
                        disabled={!premium}
                        onClick={() => {
                            navigate('/v2/OnedroTimer');
                        }}>
                        プレミアムにアップグレード
                    </Button>

                    <motion.ui
                        whileHover={{ scale: 1.1 }}
                        whileTap={{
                            scale: 0.95,
                        }}>
                        <Button
                            sx={{ height: "100px", margin: "50px", width: "500px" }}
                            variant="outlined"
                            color="primary"
                            onClick={() => {
                                navigate('/v2/SetupTimer2');
                            }}>
                            戻る
                        </Button>
                    </motion.ui>
                </Box>
            </motion.div>


        </>)
    }
}
export default SetupTimer3;



const useStyles = makeStyles((theme) => ({

    // 全体レイアウト
    contentWrapper: {
        marginTop: 20,
        flexDirection: 'column',
        alignItems: 'center',
    },
    mainBox: {
        maxWidth: "1000px",
        margin: "0 auto",

    },

    // 個別コンテンツ　中央揃えで縦に内容を並べる
    centerBox: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        backgroundColor: 'white'
    },// 個別コンテンツ　中央揃えで横に内容を並べる
    centerFlatBox: {
        display: 'flex',
        flexDirection: 'row',
        margin: 10,
        alignItems: 'center',
    },
    // 個別コンテンツ　左揃えで縦に内容を並べる
    leftCenterBox: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'left',
    },
    // 個別コンテンツ　左揃えで縦に内容を並べる
    leftRowBox: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'left',
    },
    // 個別コンテンツ　横並びに内容を並べる
    leftBox: {
        display: 'flex',
        flexDirection: 'row',
        margin: "0 auto",
    },

}));